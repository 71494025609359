/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import SystemCheckItem from "./SystemCheckItem";
import OpenViduConnector from "../../../utils/OpenViduConnector";
import config from "../../../config/awsconfig.json";
import axios from "axios";
import { FaStream, FaCheck, FaWindowClose, FaRegDotCircle } from "react-icons/fa";
import { awscontext } from "../../awscontext";
import { store } from "react-notifications-component";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
// import { TinyYolov2SizeType } from "face-api.js";
const TYPE = "pc";

function CheckStreaming(props) {
  const[connectionMessages, setConnectionMessages] = useState([
    {status: "CHECKING", text: "Trying connect to session", name: "connecToSession"},
    {status: "CHECKING", text: "Trying join session", name: "joinSession"},
    {status: "CHECKING", text: "Webcam recording trying to start", name: "webcam"},
    {status: "CHECKING", text: "ScreenShare recording trying to start", name: "screenShare"},
  ]);
  const {
    setExamFinishedByProctor,
    setExamForceFinishedByAdmin,
    setChatPanelVisible,
    getAppendMsg,
    messageList,
    setMessageList,
    setSessionList,
    setGlobalNotifyCounter,
    sessionList
  } = useContext(awscontext);
  const informationCurrent = useRef(props.information);
  const information = informationCurrent.current;
  const instituteId = information.institute.instituteId;
  const meetingId = `${information.session.sessionId}${information.student.studentId}`;
  const username = `${information.student.name} ${information.student.lastname}`;
  const [openviduSession, setOpenviduSession] = useState();
  const [canJoin, setCanJoin] = useState();
  const [retry, setRetry] = useState(false);
  const [webcamStarted, setWebcamStarted] = useState(false);
  const [screenShareStarted, setScreenShareStarted] = useState(false);
  const [info, setInfo] = useState({
    title: i18n.t("Check_Streaming"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("Please_wait_We_are_checking_your_streaming_capabilities"),
    infoCover: "col-6",
    description:
      i18n.t("This_step_will_check_whether_there_are_a_webcam"),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
    repeatButtonText: "Reconnect",
  });
  const [settings, setSettings] = useState({
    pc: {
      audioVideo: { active: false, session: null, recorder: null },
      webcam: { active: false, session: null, recorder: null },
      screenShare: { active: false, session: null, recorder: null },
    },
    mobile_0: { active: true, session: null },
  });

  const handleUnload = async (event) => {
    for (const session in sessionList) {
      if (sessionList[session]?.sessionId.includes("mobile")) {
        sessionList[session]?.signal({
          type: "webRefreshed"
        });
      }
    }

    const data = {
      instituteId: information.institute.instituteId,
      sessionId: `${information.session.sessionId}${information.student.studentId}`,
      isWeb: true
    };
    await axios.post(
      `${config.api.invokeUrl}${config.openvidu.kms.endpoints.recording.context}${config.openvidu.kms.endpoints.recording.stop.context}`,
      data
    )

  };

  useEffect(() => {
    window.addEventListener("unload", (event) => handleUnload(event));
    return () => {
      window.removeEventListener("unload", (event) => handleUnload(event));
    };
  }, []);

  const updateMeetingInformation = async (meeting) => {
    try {
      await axios.post(
        `${config.api.invokeUrl}${config.aws.gateway.endpoints.meetings.context}`,
        meeting
      );
      console.log("Meeting has successfully updated with props => ", meeting);
    } catch (err) {
      throw err;
    }
  };

  const changeConnectionStatus = (type, status) => {
    connectionMessages.filter((item) => {
      if(item.name === type) {
        item.status = status
      }
      return item
    })
  }

  const constructOpenViduRecordingSessionOverLambda = async (
    role,
    source
  ) => {
    const openviduConnector = new OpenViduConnector();
    const sessionProperties = {
      customSessionId: `${meetingId}-${source}`,
    };
    await openviduConnector.createSession(sessionProperties);
    const session = openviduConnector.initializeSession();
    let tempSessionList = sessionList;
    tempSessionList[source] = session;
    setSessionList(tempSessionList);
    setOpenviduSession(session);
    const connectionProperties = {
      role,
    };

    const connection = await openviduConnector.connectToSession(
      sessionProperties.customSessionId,
      connectionProperties
    );
    connection ? changeConnectionStatus("connecToSession", "OK") : changeConnectionStatus("connecToSession", "FAILED");

    const publisher = await openviduConnector.joinSession(
      session,
      connection,
      username,
      source,
      props.selectedDevice
    );
    publisher ? changeConnectionStatus("joinSession", "OK") : changeConnectionStatus("joinSession", "FAILED");

    let pc = publisher.stream.getRTCPeerConnection();
    let sender = await pc.getSenders().find(s => s.track?.kind === "video");
    if (source === "webcam") {
      let parameters = sender.getParameters();
      parameters.degradationPreference = "balanced";
      sender.setParameters(parameters);
      sender.track.contentHint = 'motion';
    } else if (source === "screenShare") {
      let parameters = sender.getParameters();
      parameters.degradationPreference = "maintain-resolution";
      sender.setParameters(parameters);
      sender.track.contentHint = 'detail';
    }
    
    props.setPublisher(publisher);

    publisher.on("streamAudioVolumeChange", (event) => {
      if (event.value.oldValue - event.value.newValue < -30) {
        setGlobalNotifyCounter((prev) => {
          return ({
            notifyType: true,
          })
        });
      }
    });

    publisher.on("exception", (event) => {
      console.log("****publisher exception", event.name)
    })

    session.on("signal:system", (event) => {
      const data = JSON.parse(event.data);
      if (data.state === "EXAM_FINISH_BY_PROCTOR") {
        setExamFinishedByProctor(true);
      } else if (data.state === "EXAM_FORCE_FINISHED_BY_ADMIN") {
        setExamForceFinishedByAdmin(true);
      } else if (data.state === "PROCTOR_CALL") {
        store.addNotification({
          title: null,
          message: i18n.t("Incoming_Proctor_Call"),
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        props.setIncomingCallInformation({
          status: "WAITING",
          proctorId: data.proctorId,
          meetingId: data.meetingId,
        });
      } else if (data.state === "PROCTOR_CALL_ENDED") {
        store.addNotification({
          title: null,
          message: i18n.t("Proctor_Call_Ended"),
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        props.setIncomingCallInformation({
          status: "FINISHED",
          proctorId: null,
          meetingId: null,
        });
      } else if (data.state === "DEFAULT_PROCTOR_CALL_ENDED") {
        props.setIncomingCallInformation({
          status: "FINISHED",
          proctorId: null,
          meetingId: null,
        });
      }
    });

    session.on("signal:message", (event) => {
      setChatPanelVisible(true);
      const frommesaj = JSON.parse(event.from.data).clientData;
      const incomingMessageInfo = JSON.parse(event.data);
      if (frommesaj !== username) {
        props.playNotificationSound();
        store.addNotification({
          title: null,
          message: `Message from ${frommesaj}`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        getAppendMsg(frommesaj, incomingMessageInfo.text);
      }
      messageList.push({ frommesaj, messageText: incomingMessageInfo.text });
      setMessageList(messageList);
    });

    session.on("exception", (event) => console.log("****session exception", event.target.sessionId))
    session.on("connectionCreated", (event) => console.log("****connection created", event.target.sessionId))
    session.on("connectionDestroyed", (event) => console.log("****connection destroyed", event.target.sessionId))
    session.on("networkQualityLevelChanged", (event) => console.log("****networkqualitylevel changed", event.target.sessionId))
    session.on("streamCreated", (event) => console.log("****stream created", event.target.sessionId))
    session.on("streamDestroyed", (event) => console.log("****stream destroyed", event.target.sessionId))
    session.on("recordingStarted", (event) => console.log("****recording started", event.target.sessionId))
    session.on("recordingStopped", (event) => console.log("****recording stopped", event.target.sessionId))

    

    const meetingInformation = {
      meetingId: sessionProperties.customSessionId,
      instituteId,
      archive: false,
      sessionId: information.session.sessionId,
      studentId: information.student.studentId,
      latestActiveTime: new Date().getTime(),
      state: "ACTIVE",
      startTime: new Date().getTime(),
      source: source,
    };
    // updateMeetingInformation(meetingInformation);
    if (source === "webcam") {
      session.signal({
        data: JSON.stringify({
          state: "DEFAULT_STUDENT_CALL_ENDED",
        }),
        type: "system",
      });
    }
    if (getReadyStateBasedOnSessionType()) {
      session.on("recordingStarted", async (event) => {
        if (source === "webcam") {
          setWebcamStarted(true);
          await incrementCounterOverLambda("webcam");
          changeConnectionStatus("webcam", "OK")
        } else if (source === "screenShare") {
          setScreenShareStarted(true);
          await incrementCounterOverLambda("screenShare");
          changeConnectionStatus("screenShare", "OK")
        }
        meetingInformation.startTime = new Date().getTime();
        updateMeetingInformation(meetingInformation);
        setSettings((prevSettings) => ({
          ...prevSettings,
          [TYPE]: {
            ...prevSettings[TYPE],
            [source]: {
              active: true,
              session,
              recorder: event.target,
            },
          },
        }));
      });
      // const name = `${username.replaceAll(
      //   " ",
      //   "-"
      // )}-${source}-${Date.now().toString()}`;
      const recordingProperties = {
        session: sessionProperties.customSessionId,
        name: meetingId,
        outputMode: "INDIVIDUAL",
        frameRate: 20,
        resolution: "640x480",
      };
      await openviduConnector.startRecordingOnSession(recordingProperties);
      await axios.post(
        `${config.api.invokeUrl}/machineLearning/${information.session.sessionId}/${information.student.studentId}`
      );
    } else {
      publisher.on("streamCreated", (event) => {
        if (
          event.target.session.sessionId === sessionProperties.customSessionId
        ) {
          setSettings((prevSettings) => ({
            ...prevSettings,
            [TYPE]: {
              ...prevSettings[TYPE],
              [source]: {
                active: true,
                session,
                recorder: event.target,
              },
            },
          }));
        }
      });
    }
  };

  

  useEffect(() => {
    const retriableOpenviduRecordingSessionOverLambda = async (
      role,
      source
    ) => {
      try {
        await constructOpenViduRecordingSessionOverLambda(role, source);
      } catch (err) {
        setTimeout(() => {
          console.log("Error occurred waiting 5 seconds, before reconnection");
        }, 5000);
        if (err.name === "SCREEN_CAPTURE_DENIED") {
          //saltunay
          changeConnectionStatus("screenShare", "FAILED")
          store.addNotification({
            title: i18n.t("Screen_Capture_Denied"),
            message:
              i18n.t("You_cannot_deny_the_screen_capture"),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        } else if (err?.code === 202) {
          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        } else if (err?.code === 40007) {
          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        } else if (err instanceof TypeError) {
          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        } else if (err.response?.status === 404) {
          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        } else if (err.response?.status === 502) {
          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        } else if (err.response?.config?.url.includes("/recordings/start")) {
          console.log("****Error: recording start failure, send record stop request in here");
          store.addNotification({
            title: "recording failure",
            message: "recording start failure, send record stop request in here",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });

          changeConnectionStatus("webcam", "FAILED");
          changeConnectionStatus("screenShare", "FAILED");

          setTimeout(() => {
            retriableOpenviduRecordingSessionOverLambda(role, source);
          }, 5000);
        }
        if (!err.name === "SCREEN_CAPTURE_DENIED") {
          if (!webcamStarted) {
            console.log("****webcam recording could not be started. Trying again...");
            changeConnectionStatus("webcam", "FAILED")
            setTimeout(() => {
              retriableOpenviduRecordingSessionOverLambda("PUBLISHER", "webcam");
            }, 20000);
          } if (!screenShareStarted) {
            changeConnectionStatus("screenShare", "FAILED")
            console.log("screenShare recording could not be started. Trying again...");
            setTimeout(() => {
              retriableOpenviduRecordingSessionOverLambda("PUBLISHER", "screenShare");
            }, 20000);
          }
        }
      }
    };
    const configureMeetingSession = async () => {
      //saltunay todo
      await retriableOpenviduRecordingSessionOverLambda("PUBLISHER", "webcam");
      await retriableOpenviduRecordingSessionOverLambda("PUBLISHER", "screenShare");
    };

    configureMeetingSession();
  }, [retry]); // eslint-disable-line react-hooks/exhaustive-deps

  const incrementCounterOverLambda = async (source) => {
    const studentProperties = {
      studentId: information.student.studentId,
      source: source,
    };
    try {
      await axios.patch(
        `${config.api.invokeUrl}/studentResults/incrementCounter`,
        studentProperties,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
    } catch (error) {
      console.log("Error when incrementing counter => ", error);
    }
  };

  useEffect(() => {
    if (settings[TYPE].webcam.active && settings[TYPE].screenShare.active) {
      setInfo((info) => ({
        ...info,
        ready: "OK",
        waitMessage: i18n.t("Success_Please_click_Next_Step_to_proceed"),
      }));
    }
  }, [settings]);

  useEffect(() => {
    return () => {
      window.addEventListener("beforeunload", function (event) {
        let message = i18n.t("Are_you_sure_you_want_to_close");
        (event || window.event).returnValue = message;
        return message;
      });
    };
  });

  useEffect(() => {
    const data = {
      instituteId: information.institute.instituteId,
      sessionId: `${information.session.sessionId}${information.student.studentId}`,
    };
    axios.post(
      `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}`,
      data
    );

    if (canJoin) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        repeatButtonText: "Reconnect",
        repeatButtonDisabled: false
      }))
    }
  }, [canJoin])

  const getReadyStateBasedOnSessionType = () => {
    switch (information.session.proctorServiceType) {
      case "Record_and_Review":
      case "Live_Proctoring":
      case "Live_Proctoring_Plus":
        return true;
      case "Live_Proctoring_Lite":
        return false;
      default:
        return false;
    }
  };

  const buttonHandler = () => {
    props.setSettings(settings);
    props.setChatSession(settings.pc.webcam.session);
    props.buttonHandler();

  };
  const handleRepeatClick = () => {
    if (openviduSession) {
      openviduSession.disconnect();
      setInfo((prevInfo) => ({
        ...prevInfo,
        repeatButtonText: "Please Wait",
        repeatButtonDisabled: true
      }))
      setInterval(() => {
        setCanJoin(!canJoin)
        setRetry(!retry);
        setInfo((prevInfo) => ({
          ...prevInfo,
          repeatButtonText: "Reconnect",
          repeatButtonDisabled: false
        }))
      }, 10000);

    }
  }

  const consoleBoxStyle = {
    position: "fixed",
    bottom: "16px",
    right: "16px",
    minWidth: "360px",
    border: "thin solid #29377e",
    backgroundColor: "#fff",
    padding: "24px",
    borderRadius: "6px"
  }

  const consoleBoxTextStyle = {
    margin: "0 0 6px 0",
    padding: "0 0 6px 0",
    borderBottom: "thin solid #bdc3c7",
    textAlign: "left",
    color: "rgb(141, 141, 142)",
    fontWeight: "500",
    fontSize: "16px"
  }

  const IconStyle = {
    margin: "0 8px 0 0"
  }

  return (
    <React.Fragment>
      <SystemCheckItem
        Icon={
          <FaStream
            size={150}
            color={info.ready === "OK" ? "#29377e" : "gray"}
          />
        }
        info={info}
        buttonHandler={buttonHandler}
        next={props.next}
        prev={props.prev}
        configuration={props.configuration}
        currentStep={props.currentStep}
        handleRepeatClick={handleRepeatClick}
        handleRepeatButton={handleRepeatClick}
        repeatableButton={false}
        isClose={true}
      />
      <div style={consoleBoxStyle}>
        {connectionMessages.map((msg) => {
          return (
            <p key={msg.name} style={consoleBoxTextStyle}>
              {
                msg.status === "CHECKING" ? 
                <span style={IconStyle}>
                  <FaRegDotCircle color="orange" margin="0 8px 0 0" />
                </span> : 
                msg.status === "OK" ? 
                <span style={IconStyle}>
                  <FaCheck color="green" margin="0 8px 0 0" />
                </span> : 
                <span style={IconStyle}>
                  <FaWindowClose color="red" margin="0 8px 0 0" />
                </span>
              }
              <span>{msg.text}</span>
            </p>
          )
        })}
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(CheckStreaming)
