/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from "react";
import { awscontext } from "../../awscontext";
import axios from "axios";
import { store } from "react-notifications-component";
import { Button, Modal } from "react-bootstrap";
import { CgChevronRight } from "react-icons/cg";
import { TiArrowBack } from "react-icons/ti";
import { MdDoneAll, MdCameraswitch } from "react-icons/md";
import Webcam from "react-webcam";
import ReactLoading from "react-loading";
import i18n from "../../../config/i18n";
import { withNamespaces } from "react-i18next";
const config = require("../../../config/awsconfig.json");
const theme = require("../../../config/theme.json");

function ExamStudent(props) {
  const webcamRef = useRef(null);
  const { setUsername, globalNotifyCounter, setGlobalNotifyCounter, defaultWarnings, isAIactive, showToStudent, sessionList } = useContext(awscontext);
  const information = props.information;
  const settings = props.settings;
  const [show, setShow] = useState(false);
  const [showConnectingModal, setShowConnectingModal] = useState(false);
  const [focusGone, setFocusGone] = useState(false);
  const [notifyCounter, setNotifyCounter] = useState({
    counter: 0,
    notifyType: 0,
    prevnotify: 0,
  });
  //test
  const [cameraPos, setCameraPos] = useState(-20000);
  const networkErrorCounterRef = useRef(0);
  let reconnectingTimes = [];
  useEffect(() => {
    const interval = setInterval(() => {
      let extraTime = Number(props.information.student.examExtraDuration) * 60 * 1000;
      let sessionEndDateTimeUnix = Number(props.information.session.endDateTimeUnix) + extraTime;
      let currentDateTimeUnix = new Date().getTime();
      if (sessionEndDateTimeUnix < currentDateTimeUnix) {
        props.setPageState(props.pageState + 2);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  let examUrl;
  if (props.content === "PREEXAM") {
    examUrl = props.information.session.preExamSystemCheckLink;
  } else if (props.content === "EXAM") {
    examUrl = props.information.session.examUrl;
  }

  const CheckNetworkConnectionStatus = () => {
    if (!navigator.onLine) {
      setShowConnectingModal(true);
      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 9,
          prevnotify: prev.notifyType,
        };
      });
    }
  }

  useEffect(() => {
    sessionList?.mobile_0?.openvidu?.session.on("signal:pressedMobileFinishExam", (event) => {
      clickedFinishedButton();
    });

    sessionList?.mobile_0?.openvidu?.session.on("streamDestroyed", (event) => {
      let reason = event.reason;
      let source = "mobile_0";
      if (reason === "networkDisconnect") {
        console.log(`asdf => ${source} streamDestroyed networkDisconnect`);
        mobileCameraAlert();
        // stopMobileRecording(source);
      } else if (reason === "disconnect") {
        console.log(`asdf => ${source} streamDestroyed networkDisconnect`);
        mobileCameraAlert();
      }
    });

    setInterval(CheckNetworkConnectionStatus, 5000);

    sessionList.screenShare.openvidu.session.on('reconnecting', () => {
      console.log('----- saltunay -----> Oops! Trying to reconnect to the session');
      reconnectingTimes = [...reconnectingTimes, (new Date()).getTime()];
      if (localStorage.getItem("reconnectingTimes")) {
        localStorage.removeItem("reconnectingTimes");
      }
      localStorage.setItem("reconnectingTimes", JSON.stringify(reconnectingTimes));

      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 10,
          prevnotify: prev.notifyType,
        };
      });

      setShowConnectingModal(true);
    });

    sessionList.screenShare.openvidu.session.on('reconnected', () => {
      console.log('----- saltunay -----> Hurray! You successfully reconnected to the session')
      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 11,
          prevnotify: prev.notifyType,
        };
      });
    });

    sessionList.screenShare.openvidu.session.on('sessionDisconnected', (event) => {
        if (event.reason === 'networkDisconnect') {
            console.log('----- saltunay -----> Dang-it... You lost your connection to the session');
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 12,
                prevnotify: prev.notifyType,
              };
            });
        } else {
            console.log(`----- saltunay -----> ${event.reason}`)
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 13,
                prevnotify: prev.notifyType,
              };
            });
        }
    });

    sessionList.screenShare.openvidu.session.on('exception', (event) => {
      if (event.name === 'ICE_CONNECTION_DISCONNECTED') {
        var stream = event.origin;
        console.log('------ saltunay ----> ICE_CONNECTION_DISCONNECTED: Stream ' + stream.streamId + ' disconnected!');
        setNotifyCounter((prev) => {
          return {
            counter: prev.counter + 1,
            notifyType: 14,
            prevnotify: prev.notifyType,
          };
        });
        handleCloseConnectionModal()
      }
      if (event.name === 'ICE_CONNECTION_FAILED') {
          console.log('----- saltunay -----> ICE_CONNECTION_FAILED: Stream ' + stream.streamId + ' broke!');
          setNotifyCounter((prev) => {
            return {
              counter: prev.counter + 1,
              notifyType: 15,
              prevnotify: prev.notifyType,
            };
          });
          handleCloseConnectionModal()
      }
    });

  }, []);

  useEffect(() => {
    sessionList.webcam.openvidu.session.on('reconnecting', () => {
      console.log('----- saltunay -----> Oops! Trying to reconnect to the session');
      reconnectingTimes = [...reconnectingTimes, (new Date()).getTime()];
      if (localStorage.getItem("reconnectingTimes")) {
        localStorage.removeItem("reconnectingTimes");
      }
      localStorage.setItem("reconnectingTimes", JSON.stringify(reconnectingTimes));

      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 16,
          prevnotify: prev.notifyType,
        };
      });

      setShowConnectingModal(true);
    });

    sessionList.webcam.openvidu.session.on('reconnected', () => {
      console.log('----- saltunay -----> Hurray! You successfully reconnected to the session')
      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 16,
          prevnotify: prev.notifyType,
        };
      });
    });

    sessionList.webcam.openvidu.session.on('sessionDisconnected', (event) => {
        if (event.reason === 'networkDisconnect') {
            console.log('----- saltunay -----> You lost your connection to the session');
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 17,
                prevnotify: prev.notifyType,
              };
            });
        } else {
            console.log(`----- saltunay -----> ${event.reason}`)
        }
    });

    sessionList.webcam.openvidu.session.on('exception', (event) => {
      if (event.name === 'ICE_CONNECTION_DISCONNECTED') {
        var stream = event.origin;
        console.log('------ saltunay ----> ICE_CONNECTION_DISCONNECTED: Stream ' + stream.streamId + ' disconnected!');
        setNotifyCounter((prev) => {
          return {
            counter: prev.counter + 1,
            notifyType: 18,
            prevnotify: prev.notifyType,
          };
        });
      }
      if (event.name === 'ICE_CONNECTION_FAILED') {
          console.log('----- saltunay -----> ICE_CONNECTION_FAILED: Stream ' + stream.streamId + ' broke!');
          setNotifyCounter((prev) => {
            return {
              counter: prev.counter + 1,
              notifyType: 19,
              prevnotify: prev.notifyType,
            };
          });
      }
    });

    sessionList?.mobile_1?.openvidu?.session.on("signal:pressedMobileFinishExam", (event) => {
      clickedFinishedButton();
    });

    sessionList?.mobile_1?.openvidu?.session.on("streamDestroyed", (event) => {
      let reason = event.reason;
      let source = "mobile_1";
      if (reason === "networkDisconnect") {
        console.log(`asdf => ${source} streamDestroyed networkDisconnect`);
        mobileCameraAlert();
        // stopMobileRecording(source);
      } else if (reason === "disconnect") {
        console.log(`asdf => ${source} streamDestroyed networkDisconnect`);
        mobileCameraAlert();
      }
    });
  }, []);

  useEffect(() => {
    sessionList?.mobile_2?.openvidu?.session.on("signal:pressedMobileFinishExam", (event) => {
      clickedFinishedButton();
    });

    sessionList?.mobile_2?.openvidu?.session.on("streamDestroyed", (event) => {
      let reason = event.reason;
      let source = "mobile_2";
      if (reason === "networkDisconnect") {
        console.log(`asdf => ${source} streamDestroyed networkDisconnect`);
        mobileCameraAlert();
        // stopMobileRecording(source);
      } else if (reason === "disconnect") {
        console.log(`asdf => ${source} streamDestroyed disconnect`);
        mobileCameraAlert();
      }
    });
  }, []);

  useEffect(() => {
    sessionList?.mobile_3?.openvidu?.session.on("signal:pressedMobileFinishExam", (event) => {
      clickedFinishedButton();
    });

    sessionList?.mobile_3?.openvidu?.session.on("streamDestroyed", (event) => {
      let reason = event.reason;
      let source = "mobile_3";
      if (reason === "networkDisconnect") {
        console.log(`asdf => ${source} streamDestroyed networkDisconnect`);
        mobileCameraAlert();
        // stopMobileRecording(source);
      } else if (reason === "disconnect") {
        console.log(`asdf => ${source} streamDestroyed disconnect`);
        mobileCameraAlert();
      }
    });
  }, []);

  const stopMobileRecording = async (source) => {
    const data = {
      instituteId: information.institute.instituteId,
      sessionId: `${information.session.sessionId}${information.student.studentId}-${source}`,
      isMobile: true,
    };
    await axios.post(
      `${config.api.invokeUrl}${config.openvidu.kms.endpoints.recording.context}${config.openvidu.kms.endpoints.recording.stop.context}`,
      data
    );
  }

  const mobileCameraAlert = () => {
    store.addNotification({
      title: null,
      message: i18n.t("Please open the SmarTest Cam mobile application!"),
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 60000,
        onScreen: true,
      },
    });
  }

  useEffect(() => {
    setUsername(`${information.student.name} ${information.student.lastname}`);
    if (!information.session.iframe) {
      window.open(examUrl, "_blank");
    }
  }, []);

  useEffect(() => {
    // if(!isAIactive) { // this state declare if ai messages active open ai messages otherwise closes
    if (isAIactive) {
      if (showToStudent) {
        if (notifyCounter.notifyType === 1) {
          store.addNotification({
            title: null,
            message: defaultWarnings.studentAbsent,
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 2) {
          store.addNotification({
            title: null,
            message: defaultWarnings.secondPerson,
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 3) {
          store.addNotification({
            title: null,
            message: defaultWarnings.studentReplaced,
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 4) {
          store.addNotification({
            title: null,
            message: defaultWarnings.gazeDirection,
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 5) {
          store.addNotification({
            title: null,
            message: defaultWarnings.voiceDetected,
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          const params = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            section: "ADD_AI_COMMENT",
            comment: `{ "AI_RESULT_VOICE_DETECTED": true }`,
          };
          settings.pc.webcam.session && settings.pc.webcam.session.signal({
            type: "ai_result",
            data: `{"AI_RESULT_VOICE_DETECTED": true}`,
          });
          const paramsStudentResult = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            type: "ADD_COMMENT",
            comments: [
              {
                text: `{"AI_RESULT_VOICE_DETECTED": true}`,
                source: "webcam",
                image: "voice",
                RecordTime: new Date().getTime(),
                time: new Date().getTime(),
                type: "AI",
              },
            ],
          };
          try {
            axios.patch(
              `${config.api.invokeUrl}/studentResults/${params.sessionId}${params.studentId}`,
              paramsStudentResult,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
          try {
            axios.patch(
              `${config.api.invokeUrl}/institutes/${params.instituteId}/students`,
              params,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
          return;
        }
        if (notifyCounter.notifyType === 6) {
          store.addNotification({
            title: null,
            message: defaultWarnings.windowChange,
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          const params = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            section: "ADD_AI_COMMENT",
            comment: `{ "AI_RESULT_EXAM_PAGE_LOST": true }`,
          };
          settings.pc.webcam.session && settings.pc.webcam.session.signal({
            type: "ai_result",
            data: `{"AI_RESULT_EXAM_PAGE_LOST": true}`,
          });
          const paramsStudentResult = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            type: "ADD_COMMENT",
            comments: [
              {
                text: `{"AI_RESULT_EXAM_PAGE_LOST": true}`,
                source: "webcam",
                image: "pagelost",
                RecordTime: new Date().getTime(),
                time: new Date().getTime(),
                type: "AI",
              },
            ],
          };
          console.log("Student Result Params", params);
          try {
            axios.patch(
              `${config.api.invokeUrl}/studentResults/${params.sessionId}${params.studentId}`,
              paramsStudentResult,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
          try {
            axios.patch(
              `${config.api.invokeUrl}/institutes/${params.instituteId}/students`,
              params,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
          return;
        }
        if (notifyCounter.notifyType === 7) {
          store.addNotification({
            title: null,
            message: 'Usage of Smartphone',
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 8) {
          store.addNotification({
            title: null,
            message: 'Unauthorized Device Presented',
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 9) {
          store.addNotification({
            title: null,
            message: 'Your network is disconected. Please fix it before take exam.',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 10) {
          store.addNotification({
            title: null,
            message: 'Oops! Trying to reconnect to the session',
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 11) {
          store.addNotification({
            title: null,
            message: 'You successfully reconnected to the session',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 12) {
          store.addNotification({
            title: null,
            message: 'Dang-it... You lost your connection to the session',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 13) {
          store.addNotification({
            title: null,
            message: 'Disconnected',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 14) {
          store.addNotification({
            title: null,
            message: 'Stream disconnected. Please! Refresh again web page.',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 15) {
          store.addNotification({
            title: null,
            message: 'ICE_CONNECTION_FAILED: Stream broke!',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 16) {
          store.addNotification({
            title: null,
            message: 'You successfully reconnected to the session.',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 17) {
          store.addNotification({
            title: null,
            message: 'You lost your connection to the session',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 18) {
          store.addNotification({
            title: null,
            message: 'ICE_CONNECTION_FAILED: Stream broke!',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
        if (notifyCounter.notifyType === 19) {
          store.addNotification({
            title: null,
            message: 'ICE_CONNECTION_FAILED: Stream broke!',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: true,
            },
          });
          return;
        }
      } else {
        if (notifyCounter.notifyType === 5) {
          const params = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            section: "ADD_AI_COMMENT",
            comment: `{ "AI_RESULT_VOICE_DETECTED": true }`,
          };
          settings.pc.webcam.session && settings.pc.webcam.session.signal({
            type: "ai_result",
            data: `{"AI_RESULT_VOICE_DETECTED": true}`,
          });
          const paramsStudentResult = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            type: "ADD_COMMENT",
            comments: [
              {
                text: `{"AI_RESULT_VOICE_DETECTED": true}`,
                source: "webcam",
                image: "voice",
                RecordTime: new Date().getTime(),
                time: new Date().getTime(),
                type: "AI",
              },
            ],
          };
          try {
            axios.patch(
              `${config.api.invokeUrl}/studentResults/${params.sessionId}${params.studentId}`,
              paramsStudentResult,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
          try {
            axios.patch(
              `${config.api.invokeUrl}/institutes/${params.instituteId}/students`,
              params,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
        } if (notifyCounter.notifyType === 6) {
          const params = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            section: "ADD_AI_COMMENT",
            comment: `{ "AI_RESULT_EXAM_PAGE_LOST": true }`,
          };
          settings.pc.webcam.session && settings.pc.webcam.session.signal({
            type: "ai_result",
            data: `{"AI_RESULT_EXAM_PAGE_LOST": true}`,
          });
          const paramsStudentResult = {
            sessionId: information.session.sessionId,
            studentId: information.student.studentId,
            instituteId: information.session.instituteId,
            type: "ADD_COMMENT",
            comments: [
              {
                text: `{"AI_RESULT_EXAM_PAGE_LOST": true}`,
                source: "webcam",
                image: "pagelost",
                RecordTime: new Date().getTime(),
                time: new Date().getTime(),
                type: "AI",
              },
            ],
          };
          console.log("Student Result Params", params);
          try {
            axios.patch(
              `${config.api.invokeUrl}/studentResults/${params.sessionId}${params.studentId}`,
              paramsStudentResult,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
          try {
            axios.patch(
              `${config.api.invokeUrl}/institutes/${params.instituteId}/students`,
              params,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    Object.keys(localStorage).find((key) =>
                      key.includes("accessToken")
                    )
                  )}`,
                },
              }
            );
          } catch (err) {
            console.log(`An error has occurred: ${err}`);
          }
        }
      }
    }
  }, [notifyCounter.notifyType, defaultWarnings]);
  // signal:ai_result Bu kısım canlıya çıkmayacak
  // useEffect(()=>{
  //   let aiData
  //   console.log("/////// setting",settings)
  //   settings.pc.webcam.session.on("ai_result", (event) => {
  //     console.log("////////////////// alooooo",event.data)
  //     aiData = JSON.parse(event.data)
  //   })
  //   setAiData(aiData);
  // },[])

  const onFocus = () => {
    setFocusGone(!focusGone)
    setNotifyCounter((prev) => {
      return {
        counter: prev.counter + 1,
        notifyType: 6,
        prevnotify: prev.notifyType,
      };
    });
  }
  const onFocusGone = () => {
    setFocusGone(false);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 0,
          prevnotify: prev.notifyType,
        };
      });
      // Send Axios request here
    }, 5000)

    return () => clearTimeout(delayDebounceFn)
  }, [notifyCounter.notifyType])
  useEffect(() => {
    if (globalNotifyCounter) {

      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 5,
          prevnotify: prev.notifyType,
        };
      });
      setGlobalNotifyCounter({
        notifyType: false,
      });


      // console.log("----------------Ses Çalışıyor")
    }

  }, [globalNotifyCounter.notifyType])
  useEffect(() => {
    window.addEventListener("visibilitychange", onFocus);
    window.addEventListener("focus", onFocusGone);

    // if (information.session.isAIactive) {
    //   console.log("**// KONUŞACAK **///", props.publisher)
    //   props.publisher.on("streamAudioVolumeChange", (event) => {
    //     if (event.value.oldValue - event.value.newValue < -15) {
    //       setNotifyCounter((prev) => {
    //         return {
    //           counter: prev.counter + 1,
    //           notifyType: 5,
    //           prevnotify: prev.notifyType,
    //         };
    //       });
    //     }
    //   });
    // }
    return () => {
      window.removeEventListener("visibilitychange", onFocus);
      window.removeEventListener("focus", onFocusGone);
    }
  }, [notifyCounter]);

  useEffect(() => {
    let getScreenshotInterval = null;
    let aiddata;
    if (props.content === "EXAM" && information.session.isAIactive) {
      settings.pc.webcam.session.on("ai_result", (event) => {
        let alertData = JSON.parse(event.data);
        if (aiddata === alertData) {
          alertData = null;
        }
        aiddata = JSON.parse(event.data);
        const params = {
          sessionId: information.session.sessionId,
          studentId: information.student.studentId,
          instituteId: information.session.instituteId,
          section: "ADD_AI_COMMENT",
          comment: aiddata,
        };
        if (information.session.showToStudent && alertData) {
          const AIResult = alertData;
          if (AIResult.AI_RESULT_TEST_TAKER_ABSENT) {
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 1,
                prevnotify: prev.notifyType,
              };
            });
          } else if (AIResult.AI_RESULT_SECOND_PERSON_VISIBLE) {
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 2,
                prevnotify: prev.notifyType,
              };
            });
          }
          else if (AIResult.AI_RESULT_HEADPHONE_DETECTED) {
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 8,
                prevnotify: prev.notifyType,
              };
            });
          }
          else if (AIResult.AI_RESULT_SMARTPHONE_DETECTED) {
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 7,
                prevnotify: prev.notifyType,
              };
            });
          } else if (AIResult.AI_RESULT_TEST_TAKER_SUBSTITUTED) {
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 3,
                prevnotify: prev.notifyType,
              };
            });
          } else if (AIResult.AI_RESULT_LOOKING_AWAY) {
            setNotifyCounter((prev) => {
              return {
                counter: prev.counter + 1,
                notifyType: 4,
                prevnotify: prev.notifyType,
              };
            });
          }
        }
        // try {
        //   params.section = "ADD_AI_COMMENT";
        //   axios.patch(
        //     `${config.api.invokeUrl}/institutes/${params.instituteId}/students`,
        //     params,
        //     {
        //       headers: {
        //         Authorization: `Bearer ${localStorage.getItem(
        //           Object.keys(localStorage).find((key) =>
        //             key.includes("accessToken")
        //           )
        //         )}`,
        //       },
        //     }
        //   );
        // } catch (err) {
        //   console.log(`An error has occurred: ${err}`);
        // }
        alertData = null;
      });
    }

    const sendRequestToPythonServer = async (data) => {
      // const res = {data:aiData};
      if (!focusGone) {
        axios.post(
          `${config.api.invokeUrl}/machineLearning/${information.session.sessionId}/${information.student.studentId}`,
          data
        ).catch((error) => {
            if (error.message === 'Network Error') {
              networkErrorCounterRef.current++;
              if (networkErrorCounterRef.current === 4) {
                networkErrorCounterRef.current = 0;
                store.addNotification({
                  title: null,
                  message:
                    'Network error, please check your internet connection and refresh the page.',
                  type: 'danger',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 10000,
                    onScreen: true,
                  },
                });
              }
              setShowConnectingModal(true)
            }
            if (error.response) {
              const responseStatusFirstDigitAsStr = String(
                error.response.status
              ).charAt(0);
              const responseStatusFirstDigit = Number(
                responseStatusFirstDigitAsStr
              );
              store.addNotification({
                title: null,
                message:
                  responseStatusFirstDigit === 4
                    ? 'Cannot access connection with server. Please check your internet connection and refresh the page.'
                    : responseStatusFirstDigit === 5
                    ? 'Server error.'
                    : '',
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 3500,
                  onScreen: true,
                },
              });
              setShowConnectingModal(true)
            }
          });
      }

    };
    const periodicallyGetScreenshot = () => {
      if (props.content === "EXAM") {
        const imageURL = webcamRef.current.getScreenshot({
          width: 640,
          height: 480,
        });
        const data = {
          imageURL,
          width: 640,
          height: 480,
        };
        sendRequestToPythonServer(data);
      }
    };
    if (webcamRef.current && information.session.isAIactive) {
      webcamRef.current.video.oncanplay = () => {
        getScreenshotInterval = setInterval(periodicallyGetScreenshot, 2000);
      };
    }
    return () => {
      aiddata = {};
      clearInterval(getScreenshotInterval);
    };
  }, [webcamRef.current]);

  useEffect(() => {
    const updateLastActiveTimeMeetingInformation = async () => {
      const meetingId = `${information.session.sessionId}${information.student.studentId}-webcam`;
      try {
        await axios.patch(
          `${config.api.invokeUrl}${config.aws.gateway.endpoints.meetings.context}/${meetingId}/${information.institute.instituteId}`,
          { latestActiveTime: new Date().getTime() }
        );
      } catch (err) {
        console.log(
          "Error sending the meeting latest update active time data: ",
          err
        );
      }
    };
    const intervalId = setInterval(
      updateLastActiveTimeMeetingInformation,
      30000
    );
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const updateSystemCheckLinkStatus = async () => {
      if (props.content === "EXAM") {
        const params = {
          studentId: information.student.studentId,
          sessionId: information.session.sessionId,
          source: "PC",
          section: "systemCheckLinkStatus",
          value: "INACTIVE",
        };
        await axios.patch(
          `${config.api.invokeUrl}/institutes/${information.institute.instituteId}/sessions/${information.session.sessionId}`,
          params
        );
      }
    };
    updateSystemCheckLinkStatus();
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseConnectionModal = () => {
    setShowConnectingModal(false)
  };

  const clickedFinishedButton = () => {
    if (props.content === "PREEXAM") {
      props.setPageState(props.pageState + 1);
    } else {
      if (
        information.session.systemCheck.postExamRoomScan ||
        information.session.systemCheck.postExamMaterialCheck
      ) {
        props.setPageState(props.pageState + 1);
      } else {
        props.setPageState(props.pageState + 2);
      }
    }
  }

  const renderModalFooter = () => {
    return (
      <div className="d-flex flex-row justify-content-around align-items-center w-100">
        <button
          className="btn buttons fontbold pr-4"
          style={{ border: "1px solid", borderRadius: 10 }}
          onClick={() => clickedFinishedButton()}
        >
          <CgChevronRight size={40} style={{ marginRight: 10 }} />
          {i18n.t("Yes_finish")}
        </button>
        <button
          className="btn buttons fontbold repeat-button repeat pr-4"
          style={{ border: "1px solid", borderRadius: 10 }}
          onClick={() => {
            setShow(false);
          }}
        >
          <TiArrowBack size={40} style={{ marginRight: 10 }} />
          {i18n.t("No_go_back")}
        </button>
      </div>
    );
  };

  const handleMobileRefresh = async () => {
    for (const session in sessionList) {
      if (sessionList[session]?.sessionId.includes("mobile")) {
        sessionList[session]?.signal({
          type: "webRefreshed"
        });
        console.log("****** auchting");
      }
    }  
  }

  const refreshExamPage = async () => {
    setShowConnectingModal(false);
    await handleMobileRefresh();
    window.location.reload();
  }

  const mainContent = () => {
    const username = `${information.student.name} ${information.student.lastname}`;
    return (
      <div
        className="col-md-11 m-auto"
        style={{
          height: "calc(100% - 70px)",
        }}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div
            className="d-flex flex-fill align-items-center justify-content-between mt-2 mb-2"
            style={{ height: "60px" }}
          >
            <div
              className="d-flex flex-column align-items-start col-md-5 p-2 "
              style={{
                backgroundColor: "#29377e",
                color: "white",
                borderRadius: "10px",
              }}
            >
              <div className="p-1">
                <span className="font-weight-bold">
                  {" "}
                  {i18n.t("Session_Name")}:{" "}
                </span>
                {information.session.sessionName}
              </div>
              <div className="p-1">
                <span className="font-weight-bold">
                  {i18n.t("Test_Taker")}:{" "}
                </span>
                {username}
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                {/* <button
                  className={`${theme.btn_save_small}`}
                  style={{ border: "1px solid", borderRadius: 10 }}
                  onClick={() => {
                    // console.log("CameraPos: ", cameraPos);
                    if (cameraPos === -20000) setCameraPos(0);
                    else setCameraPos(-20000);
                  }}
                >
                  <MdCameraswitch size={40} style={{ marginRight: 10 }} />
                  {i18n.t("Camera")}
                </button> */}

                {information.student.examRules.keyCodeDesc && (
                  <div className="d-flex flex-row">
                    <div
                      className="d-flex flex-col"
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        color: "#29377e",
                        fontSize: 14,
                      }}
                    >
                      Key Code: {information.student.examRules.keyCodeDesc}
                    </div>
                  </div>
                )}

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  className={`${theme.btn_save_small}`}
                  style={{ border: "1px solid", borderRadius: 10 }}
                  onClick={() => {
                    webcamRef.current = null;
                    setShow(true);
                  }}
                >
                  <MdDoneAll size={40} style={{ marginRight: 10 }} />
                  {i18n.t("Finish_Exam")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {information.session.iframe ? (
          <div
            id="frame"
            className="col p-0 m-0"
            style={{ height: "calc(100% - 12px)", width: "100%" }}
          >
            <iframe
              id="exam"
              title="exam"
              style={{
                height: "100%",
                width: "100%",
              }}
              src={examUrl}
            />
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <React.Fragment>
      {mainContent()}
      <Webcam
        ref={webcamRef}
        className="col-md-1 p-1"
        audio={false}
        videoConstraints={{
          deviceId: props.selectedDevice.video.deviceId,
        }}
        style={{ position: "absolute", top: 0, left: cameraPos }}
      />
      {/* {information.student.examRules.keyCodeDesc && (
        <div
          className="d-flex flex-row"
          style={{ position: "absolute", top: "50%", left: "5%" }}
        >
          <div className="d-flex flex-col">
            <ReactLoading
              type="bars"
              color="#29377e"
              height="30px"
              width="30px"
            />
          </div>

          <span>&nbsp;</span>
          <div
            className="d-flex flex-col"
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              color: "#29377e",
              fontSize: 14,
            }}
          >
            Key Code: {information.student.examRules.keyCodeDesc}
          </div>
        </div>
      )} */}

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header>
          <div
            className="m-auto text-center"
            style={{ fontWeight: "bold", color: "#29377e" }}
          >
            Please select your video source, from the available list
          </div>
        </Modal.Header> */}
        <Modal.Body
          style={{
            fontSize: 18,
            color: "rgba(141, 141, 142, 1)",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {i18n.t("Are_you_sure_you_want_to_finish_the_exam")} ?
        </Modal.Body>
        <Modal.Footer>{renderModalFooter()}</Modal.Footer>
      </Modal>
      <Modal show={showConnectingModal} onHide={handleCloseConnectionModal}>
        <Modal.Header>
          <div
            className="m-auto text-center"
            style={{ fontWeight: "bold", color: "#29377e" }}
          >
            Unstable connection!
          </div>
        </Modal.Header>
        <Modal.Body>Your network connection seen unstable. Please refresh page and be sure your network have min 8Mbps upload connection speed.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ backgroundColor: "#29377e", borderColor: "#29377e" }}
            onClick={() => refreshExamPage()}
          >
            Refresh
          </Button>
          </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default withNamespaces()(ExamStudent);
